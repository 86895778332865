import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';

import { MemberProfileSitecoreTemplate } from '../member-profile-sitecore-template';
import { UpdateMemberProfileSitecoreTemplate } from '../update-member-profile-sitecore-template';
import { MemberProfile } from '../member-profile';
import { MemberLanguages } from '../member-languages';
import { MemberPlanOperation } from '../member-plan-operation';
import { CustomerServiceResponse } from '../customer-service-response';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { UpdateMemberProfileRequest } from '../update-member-profile-request';
import { publishReplay, refCount, catchError, map } from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';
import { TCPUpdate } from '../TCPUpdate';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class MemberProfileService {
  private notify = new BehaviorSubject<string>("MemberProfileChanged");
  public notifyObservable$ = this.notify.asObservable();
  private memberProfileServiceUrl;
  private updateMemberProfileServiceUrl;
  private acknowledgeTermsOfUseServiceUrl;
  private memberLanguagesUrl;  
  private memberPlanOperationServiceUrl: string;

  private _memberProfile: MemberProfile = null;
  public get memberProfile(): MemberProfile {
    return this._memberProfile;
  }
  public set memberProfile(profile: MemberProfile) {
    if(this._memberProfile !== profile){
      this._memberProfile = profile;
      this.notifyChange();
    }
  }

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private oauthService: OAuthService,
    private languageService: LanguageService) {
    this.memberProfileServiceUrl = configuration.MemberProfileServiceUrl;
    this.updateMemberProfileServiceUrl =
      configuration.UpdateMemberProfileServiceUrl;
    this.memberLanguagesUrl = configuration.MemberLanguagesServiceUrl;
    this.memberPlanOperationServiceUrl =
      configuration.MemberPlanOperationServiceUrl;
    this.acknowledgeTermsOfUseServiceUrl =
      configuration.AcknowledgeTermsOfUseUrl;
  }
  
  public notifyChange() {
    this.notify.next("MemberProfileChanged");
  }

  clearCache() {
    this.memberProfile = null;
  }

  getMemberProfileContent(): Observable<MemberProfileSitecoreTemplate> {
    return this.http
      .get<MemberProfileSitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceMemberProfileParameter))
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  getUpdateMemberProfileContent(): Observable<UpdateMemberProfileSitecoreTemplate> {
    return this.http
      .get<UpdateMemberProfileSitecoreTemplate>(
        this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreUpdateMemberProfileParameter)
      ).pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  getMemberProfile(forceRefresh:boolean = false): Observable<MemberProfile> {
    if (!this.oauthService.hasValidAccessToken()) {
      return null;
    }
    // Caching of data, implement and update UpdateMemberProfile to use clear cache
    if (!forceRefresh && this.memberProfile && !this.isEmptyObject(this.memberProfile)) {
      return of(this.memberProfile);
    } else {
      return this.http
        .get<MemberProfile>(this.memberProfileServiceUrl)
        .pipe(
          map(response => {
            this.memberProfile = response;
            return this.memberProfile;
          }),
          catchError(error =>
            HttpResponseHelper.handleError(this.configuration, error))
        );
    }
  }

  getMemberPlanAllowedOperation(): Observable<MemberPlanOperation> {
    return this.http
      .get<MemberPlanOperation>(this.memberPlanOperationServiceUrl)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  updateMemberProfile(
    updateMemberProfileRequest: UpdateMemberProfileRequest
  ): Observable<Array<CustomerServiceResponse>> {
    return this.http
      .post<Array<CustomerServiceResponse>>(
        this.updateMemberProfileServiceUrl,
        updateMemberProfileRequest
      ).pipe(
        map(response => {
          this.getMemberProfile(true).subscribe();
          return response;
        }),
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        ));
  }

  acknowledgeTermsOfUse(acknowledged: boolean): Observable<boolean> {
    this.clearCache();
    return this.http
      .post<boolean>(
        this.acknowledgeTermsOfUseServiceUrl,
        String(acknowledged),
        httpOptions
      ).pipe(catchError(error =>
        HttpResponseHelper.handleError(this.configuration, error)
      ));
  }

  getMemberLanguages(): Observable<MemberLanguages[]> {
    return this.http
      .get<MemberLanguages[]>(this.memberLanguagesUrl)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        ));
  }

  getMemberFullName(memberProfile: MemberProfile): string {
    if (memberProfile.MiddleName) {
      return (
        memberProfile.FirstName +
        ' ' +
        memberProfile.MiddleName +
        ' ' +
        memberProfile.LastName
      );
    } else {
      return memberProfile.FirstName + ' ' + memberProfile.LastName;
    }
  }

  getMemberAge(memberProfile: MemberProfile): number {
    let age = 0;
    if (memberProfile.DateOfBirth) {
      let memberDob: any = new Date(memberProfile.DateOfBirth);

      if (memberDob) {
        let timeDiff = Math.abs(Date.now() - memberDob);
        age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
      }
    }

    return age;
  }

  isMemberAbove18yr(memberProfile: MemberProfile): boolean {
    return this.getMemberAge(memberProfile) > 17 ? true : false;
  }

  isMember21yrAndAbove(memberProfile: MemberProfile): boolean {
    return this.getMemberAge(memberProfile) > 20 ? true : false;
  }

  private isEmptyObject(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }

  TCPAUpdate(request: TCPUpdate): Observable<CustomerServiceResponse> {
    this.clearCache();
    return this.http.post<CustomerServiceResponse>(
        this.configuration.TCPAUpdateUrl, request
        )
        .pipe(
          map(response => {
            this.getMemberProfile().subscribe();
            return response;
          }),
          catchError(error =>
            HttpResponseHelper.handleError(this.configuration, error
          )));
  }

  public get firstName() {
    try {
      var represenativeFirstName: string;
      if (this.memberProfile?.PersonalRepresentative) {
        if (this.memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId !== this.configuration.PersonalRepresentativeTermed) {
        }
      }

      return !represenativeFirstName ? this.memberProfile.FirstName : represenativeFirstName;
    }
    catch (e) {
      return this.configuration.EmptyString;
    }
  }

}
