
import { Component, OnInit, Input } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Configuration } from '../../app.constants';
import { JwtHelperService } from '@auth0/angular-jwt'
import { timer, Subscription } from 'rxjs'
import { SessionTimerSitecoreService } from '../shared/services/session-timer-sitecore.service'
import { SessionTimerSitecoreTemplate } from '../shared/session-timer-sitecore-template';
import { INotifyChange, LanguageService } from '../shared/services/language.service';
import { SecureAuthService } from '../../user/shared/services/user.service';

declare var $: any;

@Component({
    selector: 'session-timer',
    templateUrl: './session-timer.component.html',
    styleUrls: ['./session-timer.component.scss']
})

export class SessionTimerComponent implements OnInit {

    timer;
    message: string = "";
    showSessionExpiryDialogue: boolean = false;
    timeLeftForSessionToExpire: string;
    checkTimeLeftForTimeout: boolean = true;
    timerRefreshInterval: number = this.configuration.FirstSessionTimeout;
    sessionTimerSitecoreContent: SessionTimerSitecoreTemplate = new SessionTimerSitecoreTemplate();
    private subscription: Subscription;

    @Input() timeLeftForSessionToExpireInput: string;
    constructor(private authService: OAuthService, private configuration: Configuration,
        private sessionTimerSitecoreService: SessionTimerSitecoreService,
        private jwtHelper: JwtHelperService,
        private languageService: LanguageService
    ) {

    }

    ngOnInit() {
        try {
          this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
            if(change.propName === 'languageCode'){
              this.onLoad();
            }
          });
        }
        catch (ex) {
          throw ex;
        }
      }
    
      ngOnDestroy() {
        this.subscription.unsubscribe();
      }
  
      onLoad() {
        try {
            if (!this.jwtHelper.isTokenExpired) {
                this.sessionTimerSitecoreService.getSessionTimerSitecoreContent()
                    .subscribe(response => {
                        this.sessionTimerSitecoreContent = response;
                    },
                    error => this.message = <any>error);
            }
        }
        catch (ex) {
            throw ex;
        }
    }

    getTimeLeftForSessionToExpire() {
            var token = this.authService.getAccessToken();
            if (!this.jwtHelper.isTokenExpired(token)) {
                let timeLeftForexpiration: number
                timeLeftForexpiration = this.jwtHelper.getTokenExpirationDate(token).getTime() - Date.now();
                if (timeLeftForexpiration <= this.configuration.TimeToShowSessionTimerBeforeSessionExpires) {
                    this.timerRefreshInterval = this.configuration.SecondSessionTimeout;
                    if (!this.showSessionExpiryDialogue) {
                        this.showSessionExpiryDialogue = true;
                        this.showSessionTimeoutDialog();
                    }
                    return this.formatTimeLeftForSesssionToExpire(timeLeftForexpiration);
                }
            }
            else {
                if(token)
                    this.logOut();
                this.unsubscibeFromTimer();
                
            }
    }

    public logOut() {
        this.authService.logOut(false);
        sessionStorage.removeItem(this.configuration.SessionStateKey);
    }

    showSessionTimeoutDialog() {
        $(this.configuration.SessionTimerModalId).modal({ show: this.showSessionExpiryDialogue });
    }

    unsubscibeFromTimer() {
        this.checkTimeLeftForTimeout = false;
    }

    refreshObservable() {
        return timer(this.timerRefreshInterval);
    }

    formatTimeLeftForSesssionToExpire(timeLeftForExpiration): string {
        var minutesLeftForExpiration;
        var secondsLeftForExpiration;

        secondsLeftForExpiration = this.formatSecondsLeftForSessionToExpire(timeLeftForExpiration);
        if (timeLeftForExpiration > this.configuration.FiftyNineSecondsInMillisSeconds) {
            minutesLeftForExpiration = this.formatMinutesLeftForSessionToExpire(timeLeftForExpiration);
            return minutesLeftForExpiration + this.configuration.TimerColon + secondsLeftForExpiration + this.configuration.SpaceString + this.configuration.MinutesLabel;
        }
        else {
            return this.configuration.SecondsPrefix + secondsLeftForExpiration + this.configuration.SpaceString + this.configuration.SecondsLabel;
        }
    }

    formatMinutesLeftForSessionToExpire(timeLeftForExpiration) {
        var minutesLeftForExpiration;
        minutesLeftForExpiration = Math.floor(timeLeftForExpiration / this.configuration.SixtySecondsInMilliSeconds)
        if (minutesLeftForExpiration < this.configuration.TenMinutes)
            minutesLeftForExpiration = this.configuration.MinutesPrefix + minutesLeftForExpiration;
        return minutesLeftForExpiration;
    }

    formatSecondsLeftForSessionToExpire(timeLeftForExpiration) {
        var secondsLeftForExpiration;
        if (timeLeftForExpiration > this.configuration.FiftyNineSecondsInMillisSeconds) {
            secondsLeftForExpiration = Math.round((timeLeftForExpiration % this.configuration.SixtySecondsInMilliSeconds) / this.configuration.OneSecondInMilliSeconds)
        }
        else {
            secondsLeftForExpiration = Math.round(timeLeftForExpiration / this.configuration.OneSecondInMilliSeconds)
        }

        if (secondsLeftForExpiration < this.configuration.TenSeconds)
            secondsLeftForExpiration = this.configuration.MinutesPrefix + secondsLeftForExpiration;
        return secondsLeftForExpiration;
    }

}