
import { Component, OnInit } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';
import { Configuration } from '../../app.constants';

@Component({
    selector: 'app-logoff',
    templateUrl: './logoff.component.html',
    styleUrls: ['./logoff.component.scss']
})
export class LogoffComponent implements OnInit {

    public message: string;
    private redirectUrl: string = this.configuration.LoginUrl;

    constructor(private authService: OAuthService,
        public configuration: Configuration
      ) {
    }

    ngOnInit() {
        this.CleanUp();
        this.Logoff();
        window.location.href = this.redirectUrl;
    }

    public CleanUp() {
      const userEditValue = sessionStorage.getItem(this.configuration.UserProfileEditSessionKey);
      if (userEditValue === this.configuration.Yes) {
        this.redirectUrl = this.configuration.SelfServiceRestartUrl;
        sessionStorage.removeItem(this.configuration.UserProfileEditSessionKey);
      } else {
        this.redirectUrl = this.configuration.LoginUrl;
      }
    }

    public Logoff() {
      sessionStorage.removeItem(this.configuration.SessionStateKey);

      // Remove the local storage that was saved on the Health Assessemnt Survey
      for (let i = 0; i < window.localStorage.length; i++) {
        if (window.localStorage.key(i).includes(this.configuration.SurveyStoragePrefix)) {
          window.localStorage.removeItem(localStorage.key(i));
        }
      }

      this.authService.logOut();
    }
}
