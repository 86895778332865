import { Component, OnInit, Directive, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Configuration } from '../../app.constants';
import { Member } from '../shared/member';
import { TermsOfUseService } from '../shared/services/terms-of-use.service';
import { TermsOfUseTemplate } from '../shared/terms-of-use-sitecore-template';

import { MemberProfile } from '../shared/member-profile';
import { MemberProfileService } from '../shared/services/member-profile.service';
import { finalize } from 'rxjs/operators';
import { INotifyChange, LanguageService } from '../../core/shared/services/language.service';
import { Subscription, Observable } from 'rxjs';

@Component({

  templateUrl: './terms-of-use.component.html',
  providers: [TermsOfUseService],
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit, OnDestroy {
  public member: Member[];
  public viewTemplate = new TermsOfUseTemplate();
  public termsOfUse$: Observable<TermsOfUseTemplate>;
  public isContentServiceBusy: boolean = true;
  public isMemberServiceBusy: boolean = true;
  public memberProfile: MemberProfile = new MemberProfile();
  private model: Object;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private config: Configuration,
    private memberProfileService: MemberProfileService,
    private termsOfUseService: TermsOfUseService,
    private languageService: LanguageService
  ) { }

  public message: string;
  ngOnInit() {
    try {
      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.onLoad();
        }
      });
    }
    catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLoad() {
    try {
      this.memberProfileService.getMemberProfile()
        .pipe(finalize(() => this.isMemberServiceBusy = false))
        .subscribe(response => {
          this.memberProfile = response;
          return response;
        },
          error => {
            this.message = <any>error;
          },
        );

      this.termsOfUseService.getTermsOfUseContent()
        .pipe(
          finalize(() => this.isContentServiceBusy = false))
        .subscribe(response => {
          let temp = response;
          this.viewTemplate = response;
          return response;
        },
          error => {
            this.message = <any>error;
          }
        );
    }
    catch (ex) {
      this.isContentServiceBusy = false;
      this.isMemberServiceBusy = false;
      throw ex;
    }
  }

  public acknowledgeTermsOfUse() {
    try {
      this.isMemberServiceBusy = true;
      this.memberProfileService.acknowledgeTermsOfUse(true)
        .pipe(finalize(() => this.isMemberServiceBusy = false))
        .subscribe(response => {
          this.router.navigate(['/dashboard']);                
          return response;
        },
          error => {            
            var message = <any>error;
          });

      window.scrollTo(0, 0);
    }
    catch (ex) {
      this.isMemberServiceBusy = false;
      throw ex;
    }
  }
}
