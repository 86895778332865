export const environment = {
  production: true,
  forbiddenNumbers: [
    "000-000-0000",
    "111-111-1111",
    "222-222-2222",
    "333-333-3333",
    "444-444-4444",
    "555-555-5555",
    "666-666-6666",
    "777-777-7777",
    "888-888-8888",
    "999-999-9999",
    "123-456-7890",
    "987-654-3210",
    "222-333-4444",
    "714-246-8400",
    "714-246-8600",
    "888-587-8088",
  ],
  exclusionMapHN: [
    //'CSPI_ID|HN_ID|HiddenDate|IsShownAfter|EffectiveEndDate|EffectiveDate', // Match all three: CSPI_ID, HN_ID, and exclusion date
    '|HMO04MC|2023-11-01|0||',  // Hide HN after the HiddenDate
    '|SRG66MC|2023-12-01|0||',
    '|SRG65MC|2023-12-01|0||',
    '|PMG66MA|2023-12-01|0||',
    '|PMG52MA|2023-12-01|0||',
    // 'CODCNP2||2024-09-06|1|2024-11-30|2025-01-01',  // Hide CSPI_ID and Show it after the HiddenDate (2025-01-01T00:00:00)
    // 'HMO15P2||2024-09-06|1|2024-11-30|2025-01-01',
    // 'HMO16P2||2024-09-06|1|2024-11-30|2025-01-01',
    // 'PHC58P2||2024-09-06|1|2024-11-30|2025-01-01',
    // 'PMG21P2||2024-09-06|1|2024-11-30|2025-01-01',
    // 'PMG64P2||2024-09-06|1|2024-11-30|2025-01-01',
    // 'PMG69P2||2024-09-06|1|2024-11-30|2025-01-01',
    // 'PMG82P2||2024-09-06|1|2024-11-30|2025-01-01',
  ],
  filterRulesHN: [
    { "suffix": "DE", "exclude": "P2" },
    { "suffix": "P2", "exclude": "DE" }
  ],
  disableSurveys: [
    // { code: 5, name: "SOGI" }
  ],
  disableVirtualVisits: false
};
