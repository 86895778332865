import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthHelper } from '../shared/helpers/auth-helper';
import { SecureAuthService } from '../../user/shared/services/user.service';

export const NoLogInGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  
  const url = '#' + state.url;
  const config: Configuration = inject(Configuration);
  const oauth: OAuthService = inject(OAuthService);
  const authHelper: AuthHelper = inject(AuthHelper);
  const secureAuthService: SecureAuthService = inject(SecureAuthService);

  const userEditValue = sessionStorage.getItem(config.UserProfileEditSessionKey);

  if (url === config.PasswordResetRelativePath && userEditValue === config.Yes) {
    sessionStorage.setItem(config.PasswordResetRequestLocalName, config.PasswordResetRequestLocalValue);
    oauth.logoutUrl = null;
    sessionStorage.removeItem(config.UserProfileEditSessionKey);
  }

  if (oauth.hasValidAccessToken() && authHelper.tokenNotExpired()) {
    return false;
  }

  return true;
}
