/*
 ** Core module exposing all core views, services, and helpers
 */
import { NgModule, ApplicationRef, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { OAuthService } from "angular-oauth2-oidc";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { routedComponents, CoreRoutingModule } from "./core-routing";
import { SiteMapComponent } from "./site-map/site-map.component";
import { AuthorizeComponent } from "./authorize/authorize.component";
import { LogoffComponent } from "./logoff/logoff.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { PhonePipe } from "./shared/pipes/phone.pipe";
import { UppercaseInputDirective } from "./shared/directives/uppercase-input.directive";
import { HeaderSitecoreService } from "./shared/services/header-sitecore.service";
import { WaitCursorComponent } from "./wait-cursor/wait-cursor.component";
import { CommaSeparatedNumberPipe } from "./shared/pipes/comma-separated-number.pipe";
import { EmailService } from "./shared/services/email.service";
import { RoutingHelper } from "./shared/helpers/routing-helper";
import { AuthHelper } from "./shared/helpers/auth-helper";
import { TruncatePipe } from "./shared/pipes/truncate.pipe";
import { SafePipe } from "./shared/pipes/safe.pipe";
import { SessionTimerComponent } from "./session-timer/session-timer.component";
import { SessionTimerSitecoreService } from "./shared/services/session-timer-sitecore.service";
import { EmailComponent } from "./shared/components/email/email.component";
import { AddressComponent } from "./shared/components/address/address.component";
import { PCPInfoComponent } from "./shared/components/pcp-info/pcp-info.component";
import { PhoneComponent } from "./shared/components/phone/phone.component";
import { ProviderLinkComponent } from "./shared/components/provider-link/provider-link.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { BlockUIModule } from "primeng/blockui";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { PhoneMaskDirective } from "./shared/directives/phone-mask.directive";
import { ZipCodeMaskDirective } from "./shared/directives/zip-code-mask.directive";
import { ZipCodePipe } from "./shared/pipes/zipcode.pipe";
import { LoggingService } from "./shared/services/logging.service";
import { ErrorsHandler } from "./shared/errors.handler";
import {
  HTTPListener,
  HTTPStatus,
} from "./interceptors/wait-cursor-interceptor";
import { NavigatorComponent } from "./navigator/navigator.component";
import { ProviderLinkService } from "./shared/services/provider-link.service";
import { PagerService } from "./shared/services/pager.service";
import { ErrorSummaryComponent } from "./error-summary/error-summary.component";
import { ErrorService } from "./shared/services/error.service";
import { MedImpactComponent } from "./med-impact/med-impact.component";
import { LanguageService } from "./shared/services/language.service";
import { LanguageComponent } from "./language/language.component";
import { DateOfBirthComponent } from "./date-of-birth/date-of-birth.component";
import { ContactCustomerServiceComponent } from "./contact-customer-service/contact-customer-service.component";
import { UserPhoneComponent } from "../user/shared/components/user-phone/user-phone.component";
import { UserPhonePipe } from "../user/shared/pipes/user-phone.pipe";
import { AppointmentDateValidatorDirective } from "./shared/directives/appointment-date-validator.directive";
import { ForbiddenPhonesValidatorDirective } from "./shared/directives/forbidden-phones-validator.directive";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { FooterCardComponent } from "./footer-card/footer-card.component";
import { AccordionComponent } from "./accordion/accordion.component";

const waitCursorServices = [HTTPListener, HTTPStatus];

@NgModule({
    imports: [
        CommonModule,
        CoreRoutingModule,
        FormsModule,
        InputSwitchModule,
        BlockUIModule,
        ProgressSpinnerModule,
    ],
    declarations: [
        routedComponents,
        SessionTimerComponent,
        AddressComponent,
        NavigatorComponent,
        ErrorSummaryComponent,
        MedImpactComponent,
        AppointmentDateValidatorDirective,
        ForbiddenPhonesValidatorDirective,
        LanguageComponent,
        DateOfBirthComponent,
        DatePickerComponent,
        UserPhoneComponent,
        UserPhonePipe,
        PhonePipe,
        ContactCustomerServiceComponent,
        FooterCardComponent,
        AccordionComponent
    ],
    exports: [
        SiteMapComponent,
        AuthorizeComponent,
        LogoffComponent,
        HeaderComponent,
        FooterComponent,
        WaitCursorComponent,
        CommaSeparatedNumberPipe,
        TruncatePipe,
        SafePipe,
        SessionTimerComponent,
        UppercaseInputDirective,
        EmailComponent,
        AddressComponent,
        PhoneComponent,
        ProviderLinkComponent,
        PCPInfoComponent,
        PhoneMaskDirective,
        ZipCodeMaskDirective,
        ZipCodePipe,
        NavigatorComponent,
        ErrorSummaryComponent,
        MedImpactComponent,
        LanguageComponent,
        DateOfBirthComponent,
        DatePickerComponent,
        UserPhoneComponent,
        UserPhonePipe,
        PhonePipe,
        ContactCustomerServiceComponent,
        AppointmentDateValidatorDirective,
        ForbiddenPhonesValidatorDirective,
        FooterCardComponent,
        AccordionComponent,
    ],
    providers: [
        HeaderSitecoreService,
        EmailService,
        AuthHelper,
        RoutingHelper,
        SessionTimerSitecoreService,
        waitCursorServices,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPListener,
            multi: true,
        },
        LoggingService,
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler,
        },
        ProviderLinkService,
        PagerService,
        ErrorService,
    ]
})
export class CoreModule {}
