import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Observer } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { RegistrationTemplate } from '../registration-template';
import { publishReplay, refCount, catchError ,  map } from 'rxjs/operators';

import { Registration } from '../registration';
import { RegistrationRequest, KnowledgeBaseObject, MemberRegistrationCompleteRequest } from '../registration-request';
import { ValidateUserRequest } from '../validate-user-request';
import { ValidateUserResponse } from '../validate-user-response';
import { FamilyRelationship } from '../family-relationship';
import { RegistrationResponse } from '../registration-response';
import { LanguageService } from '../../../core/shared/services/language.service';
import { SecondFactorTemplate } from '../second-factor-template';
import { LoginTemplate } from '../login-template';
import { ForgotUserNameTemplate } from '../forgotusername-template';
import { LoginHelpTemplate } from '../login-help-template';
import { PasswordResetTemplate } from '../passwordreset-template';
import { UserProfileTemplate } from '../user-profile-template';
import { UpdateUserProfile } from '../update-user-profile';
import { UpdateComplete } from '../update-complete';
import { GetUserProfileRequest } from '../get-user-profile-request';
import { ResetPasswordRequest } from '../reset-password-request';
import { UserProfile } from '@caloptima/authentication/models/user-profile';
import { AuthProfile } from '@caloptima/authentication/models/auth-profile';
import { OAuthService } from "angular-oauth2-oidc";

@Injectable({
  providedIn: 'root'
})

export class SecureAuthService {
  private SecureAuthContentServiceUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService,
    private authService: OAuthService) {
    this.SecureAuthContentServiceUrl =
      configuration.SitecoreContentServiceUrl;
  }

  public getRegistrationContent(): Observable<RegistrationTemplate> {
    const url = this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceRegistrationParameter);
    return this.http
      .get<RegistrationTemplate>(url)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  private setRegistrationRequest(registration: Registration): RegistrationRequest {
    let registrationRequest: RegistrationRequest = new RegistrationRequest();

    let knowledgeBase: Array<KnowledgeBaseObject> = new Array<KnowledgeBaseObject>();

    let sec1: KnowledgeBaseObject = new KnowledgeBaseObject();
    let sec2: KnowledgeBaseObject = new KnowledgeBaseObject();

    sec1.Answer = registration.SecretAnswer1;
    sec1.Question = registration.SecretQuestion1;

    sec2.Answer = registration.SecretAnswer2;
    sec2.Question = registration.SecretQuestion2;

    knowledgeBase.push(sec1);
    knowledgeBase.push(sec2);

    registrationRequest.UserProfile.KnowledgeBase = knowledgeBase;
    registrationRequest.UserProfile.userName = registration.UserName;
    registrationRequest.UserProfile.address1 = '';
    registrationRequest.UserProfile.city = '';
    registrationRequest.UserProfile.email = registration.Email;
    registrationRequest.UserProfile.firstName = registration.FirstName;
    registrationRequest.UserProfile.lastName = registration.LastName;
    registrationRequest.UserProfile.mobilePhone = registration.Phone;
    registrationRequest.UserProfile.password = registration.Password;
    registrationRequest.UserProfile.state = '';
    registrationRequest.UserProfile.userID = registration.UserName;
    registrationRequest.UserProfile.zip = '';
    registrationRequest.UserProfile.AuxID8 = registration.DateOfBirth;
    registrationRequest.UserProfile.AuxID10 = registration.CIN;
    registrationRequest.UserProfile.MemberID = registration.CIN;
    registrationRequest.UserProfile.BirthDate = registration.DateOfBirth;
    registrationRequest.UserProfile.MemberPhone = registration.MemberPhone;
    registrationRequest.UserProfile.MemberZipCode = registration.ZipCode;

    if (registration.RepresentativeAccessFlag === this.configuration.Active) {
      registrationRequest.UserProfile.RepresentativeAccessFlag = registration.RepresentativeAccessFlag;
    }
    if (registration.personalRepresentative != null) {
      registrationRequest.UserProfile.personalRepresentative = registration.personalRepresentative;
    }
    registrationRequest.clientId = this.configuration.RegionClientId;

    return registrationRequest;
  }

  public registerUser(registration: Registration): Observable<any> {
    const url = this.configuration.BaseAuthenticationServiceUrl + this.configuration.RegisterParameter;

    const registrationRequest = this.setRegistrationRequest(registration);
    return this.http
      .post<RegistrationRequest>(
        url,
        registrationRequest
      )
      .pipe(
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }

  public updateUserProfile(userProfile: UserProfile): Observable<any> {
    const url = this.configuration.BaseAuthenticationServiceUrl + this.configuration.UpdateUserProfileParameter;
    const request: AuthProfile = {
      userProfile : userProfile,
      clientId : this.configuration.RegionClientId,
      address: null,
      verificationCode: null};

    return this.http
      .post(
        url,
        request
      ).pipe(
        map(resp => {
          return resp;
        }),
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        ));
    }

  public  updateUserProfileComplete(request: UpdateComplete): Observable<any> {
    const url = this.configuration.BaseMemberServiceUrl + this.configuration.UpdateUserProfileComplete;
      return this.http.post(url, request)
          .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
  }

  public updateUserProfilePassword(username: string, password: string): Observable<any> {
    const url = this.configuration.BaseAuthenticationServiceUrl + this.configuration.UpdateUserProfilePasswordParameter;
    const request: ResetPasswordRequest = {Username : username, Password: password, ClientId : this.configuration.RegionClientId, Ip: null, AuditStatus: null};

    return this.http
      .post<Boolean>(
        url,
        request
      ).pipe(
        map(resp => {
          return resp;
        })
        ,
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
        );
    }

  public getUserProfile(username: string): Observable<UserProfile> {
    const request: GetUserProfileRequest = { Username : username, ClientId : this.configuration.RegionClientId};
    const url = this.configuration.BaseAuthenticationServiceUrl + this.configuration.GetUserProfileParameter;

    return this.http
      .post<UserProfile>(
        url,
        request
      ).pipe(
        map(resp => {
          return resp;
        }),
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        ));
    }

  public updateRegisterUser(registration: Registration): Observable<any> {
    const url = this.configuration.BaseAuthenticationServiceUrl + this.configuration.UpdateParameter;
    const registrationRequest = this.setRegistrationRequest(registration);

    return this.http
      .post<RegistrationRequest>(
        url,
        registrationRequest
      ).pipe(
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        ));
  }

  public validateUser(userName: string): Observable<any> {
    const url = this.configuration.BaseAuthenticationServiceUrl + this.configuration.ValidateParameter;
    let validateUserRequest: ValidateUserRequest = new ValidateUserRequest();
    validateUserRequest.username = userName;
    validateUserRequest.clientid = this.configuration.ClientId;
    return this.http
      .post<ValidateUserResponse>(
        url,
        validateUserRequest
      ).pipe(
        map(resp => {
          return resp;
        }),
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        ));
    }

  public getFamilyRelationships(): Observable<FamilyRelationship[]> {
    return this.http
        .get<FamilyRelationship[]>(this.configuration.FamilyRelationshipServiceUrl)
        .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
            HttpResponseHelper.handleError(this.configuration, error)
        ));
  }

  public memberRegistrationComplete(registration: Registration): Observable<boolean> {
    const url = this.configuration.MemberRegistrationCompleteUrl;
    const isPersonalRep = registration.personalRepresentative.FirstName != undefined && registration.personalRepresentative.FirstName.length>0;
    const  request : MemberRegistrationCompleteRequest = {CIN : registration.CIN, 
                                                          UserName : registration.UserName,
                                                          IsPersonalRepresentative: isPersonalRep};

    return this.http
      .post<MemberRegistrationCompleteRequest>(
        url,
        request
      )
      .pipe(
        map(resp => {
          return true;
        }),
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }

  public getSecondFactorTemplate(): Observable<SecondFactorTemplate> {
    const url = this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceSecondFactorParameter);
    return this.http
      .get<SecondFactorTemplate>(url)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  public getLoginHelpTemplate(): Observable<LoginHelpTemplate> {
    const url = this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceLoginHelpParameter);
    return this.http
      .get<LoginHelpTemplate>(url)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  public getLoginTemplate(): Observable<LoginTemplate> {
    const url = this.languageService.getContentServiceUrlWithParameter(this.configuration.LoginParameter);
    return this.http
      .get<LoginTemplate>(url)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  public getForgotusernameTemplate(): Observable<ForgotUserNameTemplate> {
    const url = this.languageService.getContentServiceUrlWithParameter(this.configuration.ForgotUsernameParameter);
    return this.http
      .get<ForgotUserNameTemplate>(url)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  public getPasswordResetTemplate(): Observable<PasswordResetTemplate> {
    const url = this.languageService.getContentServiceUrlWithParameter(this.configuration.PasswordResetParameter);
    return this.http
      .get<PasswordResetTemplate>(url)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  public getUserProfileTemplate(): Observable<UserProfileTemplate> {
    const url = this.languageService.getContentServiceUrlWithParameter(this.configuration.UserProfileParameter);
    return this.http
      .get<UserProfileTemplate>(url)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }
}
