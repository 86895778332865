import { Component, OnInit, OnDestroy } from '@angular/core';
import { Configuration } from '../../app.constants';
import { Subscription } from 'rxjs';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { UserHeaderProperties } from '../shared/user-header-properties';
import { AuthenticationService, AuthHelper, LoginResponse } from '@caloptima/authentication';
import { OAuthService } from 'angular-oauth2-oidc';
import { SecureAuthService } from '../shared/services/user.service';
import { LoginTemplate } from '../shared/login-template';
import { TwoFactorStatus } from '../../member-portal/shared/enum';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginTemplate: LoginTemplate = new LoginTemplate();
  public rememberMe: boolean = false;
  public forgotUserNameRelativePath: string = this.config.ForgotUsernameRelativePath;
  public passwordResetRelativePath: string = this.config.PasswordResetRelativePath;
  public registrationPath: string = this.config.MainRegistrationRelativePath;
  public passwordResetUrl: string = this.config.PasswordResetUrl;
  public errorMessage: string;
  public userHeaderProperties: UserHeaderProperties = new UserHeaderProperties();
  public username: string;
  public password: string;
  public loginFailed: boolean = false;
  public requiresTwoFactor = false;
  public twoFactorStatus: TwoFactorStatus = TwoFactorStatus.START;
  private subscription: Subscription;

  constructor(private authService: AuthenticationService,
    private siteCoreService: SecureAuthService,
    private authHelper: AuthHelper,
    private oauth: OAuthService,
    private languageService: LanguageService,
    private config: Configuration
    ) { }

  ngOnInit() {
    this.errorMessage = '';
    try {
      const passRestRequest = sessionStorage.getItem(this.config.PasswordResetRequestLocalName);
      if (passRestRequest === this.config.PasswordResetRequestLocalValue ) {
        sessionStorage.removeItem(this.config.PasswordResetRequestLocalName);
        window.location.href = this.config.PasswordResetUrl;
      }

      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.loadView();
        }
      });

    } catch (ex) {
      throw ex;
    }
  }

  private loadView() {
    this.siteCoreService.getLoginTemplate().subscribe(
      response => {
        this.loginTemplate = response;
        this.loadHeaderView();
        this.checkLocalVariables();
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  private checkLocalVariables(): void {
    const localUserName: string = localStorage.getItem(this.config.RememberMeLocalName);
      if (localUserName !== null) {
        this.username = atob(localUserName);
        this.rememberMe = true;
      }

      const passExpiredValue: string = localStorage.getItem(this.config.LoginPasswordExpiredLocalName);

      if (passExpiredValue !== null) {
        const passExpiredValueDescrypted =  atob(passExpiredValue);

        if (this.config.LoginPasswordExpiredLocalValue === passExpiredValueDescrypted) {
          this.showErrorMessage(this.loginTemplate.ErrorMessageTempPasswordInvalid);
        }
        localStorage.removeItem(this.config.LoginPasswordExpiredLocalName);
      }
  }

  onLogin() {
    this.errorMessage = '';
    this.authService.loginUser(this.username, this.password, this.config.ClientId, this.rememberMe).subscribe((res) => {
      const loginResponse: LoginResponse = res;
      if (loginResponse.requiresTwoFactor) {
        this.requiresTwoFactor = true;
      } else if (loginResponse.isRedirect) {
        window.location.href = loginResponse.redirectUrl;
      }
      else if (loginResponse.requiresPasswordReset === true) {
        localStorage.setItem(this.config.PasswordExpiredLocalName, btoa(this.username));
        window.location.href = this.passwordResetUrl;
      }
      else if (loginResponse.requiresTwoFactor === false) {
        this.getToken();
      }
    },
    error => {
      if (error.error === this.config.ErrorMessageStatusAccountLocked) {
        this.showErrorMessage(this.loginTemplate.ErrorMessageAccountLocked);
      } else {
        this.showErrorMessage(this.loginTemplate.ErrorMessageDefault);
      }
    });
  }

  public SecondFactorChanged(value) {
    if (value.status === TwoFactorStatus.START) {
      this.requiresTwoFactor = false;
    } else if (value.status === TwoFactorStatus.PENDING_1) {
      this.errorMessage = '';
    } else if (value.status === TwoFactorStatus.PENDING_2) {
      this.errorMessage = '';
    }
    else if (value.status === TwoFactorStatus.PASSCODE_ERROR) {
      if (value.message === '') {
        this.showErrorMessage(this.config.defaultIncorrectPasscodeMessage);
      } else {
        this.showErrorMessage(value.message);
      }
    } else if (value.status === TwoFactorStatus.ANSWER_ERROR) {
      if (value.message === '') {
        this.showErrorMessage(this.config.defaultIncorrectAnswersMessage);
      } else {
        this.showErrorMessage(value.message);
      }
    } else if (value.status === TwoFactorStatus.SUCCESS) {
      this.errorMessage = '';
      this.getToken();
    }
    else if (value.status === TwoFactorStatus.ERROR) {
      this.requiresTwoFactor = false;
      this.loginFailed = true;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public loadHeaderView(): void {
    this.userHeaderProperties.MainTitle = this.loginTemplate.ApplicationTitle;
  }

  private showErrorMessage(message: string): void {
    this.errorMessage = message;
  }

  private getToken() {
    if (this.rememberMe) {
      localStorage.setItem(this.config.RememberMeLocalName, btoa(this.username));
    } else {
      localStorage.removeItem(this.config.RememberMeLocalName);
    }

    this.oauth.createAndSaveNonce().then(nonce => {
      const scope = this.config.Scope.split(' ');
      const getToken$ = this.authService.getToken(this.config.ClientId, this.config.RedirectUri, scope, nonce, false)
        .subscribe((resp) => {
          const loginSub$ = this.authHelper.oauthLogin(resp)
            .subscribe(() => {
                if (loginSub$ != null) {
                loginSub$.unsubscribe();
              }
            },
              () => {
                this.showErrorMessage(this.loginTemplate.ErrorMessageDefault);
                // log error
              },
              () => {
              });
          if (getToken$ != null) {
            getToken$.unsubscribe();
          }
        });
    });
  }
}

